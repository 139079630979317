import { Injectable } from "@angular/core";
import { BehaviorSubject, EMPTY, Observable, catchError, filter, first, merge, switchMap, tap } from "rxjs";
import { ApiClientPiiClassifierService } from "src/app/api/api-client-pii-classifier.service";
import { PiiSchemaDeepDiveResponse, PiiSchemaItem, PiiSchemaItemResponse, PiiSystemSchemaResponse } from 'src/app/api/models/pii-classifier/pii-classifier.interface';
import { LoggerService } from "src/app/logger/logger.service";
import { SystemSchemaStore } from "./system-schema.store";
import { SystemSchemaQuery } from "./system-schema.query";
import { TableColumn } from "src/app/shared/models/table-column.interface";
import { ContentPipe } from "src/app/services/content/content.pipe";
import { FeatureFlagQuery } from "src/app/feature-flag/state/feature-flag.query";
import { DataTypesQuery } from "src/app/data-types/state/data-types.query";
import { ConvertPiiTypeToDataTypePipe } from "src/app/shared/pipes/convert-pii-type-to-data-type.pipe";
import { FrameworksService } from "src/app/data-types/services/frameworks.service";
import { PoliciesService } from "src/app/policies/state/policies.service";
import { SystemsQuery } from "src/app/systems/state/systems.query";

@Injectable({
	providedIn: 'root',
})
export class SystemSchemaService {
  private readonly loggerName: string = 'SystemSchemaService';

  // save dynamic schemas columns
	private schemaColumns = new BehaviorSubject<string[]>(null);
	schemaColumns$ = this.schemaColumns.asObservable();
    
  constructor(
    private apiClientPiiClassifierService: ApiClientPiiClassifierService,
    private systemSchemaStore: SystemSchemaStore,
    private systemSchemaQuery: SystemSchemaQuery,
    private logger: LoggerService,
    private contentPipe: ContentPipe,
    private featureFlagQuery: FeatureFlagQuery,
    private dataTypesQuery: DataTypesQuery,
    private convertPiiTypeToDataTypePipe: ConvertPiiTypeToDataTypePipe,
    private frameworksService: FrameworksService,
    private policiesService: PoliciesService,
    private systemsQuery: SystemsQuery,
  ) {
  }

	initSchema(systemId: string, integrationId: string): Observable<PiiSchemaItem[]> {
      const loading$ = this.systemSchemaQuery.selectLoading();

      const set$ = loading$.pipe(
          filter(response => !response),
          switchMap(() => this.systemSchemaQuery.selectAll())
      );

      const notSet$ = loading$.pipe(
          filter(response => response),
          switchMap(() => this.getPiiSystemSchemas(systemId, integrationId)),
          switchMap(() => this.systemSchemaQuery.selectAll()),
      );

      return merge(set$, notSet$).pipe(first(), tap(() => this.systemSchemaStore.setLoading(false))
    );
  }

	resetSchema(): void {
		this.schemaColumns.next(null);
		this.systemSchemaStore.reset();
    this.systemSchemaStore.setLoading(true);
	}

  getPiiSystemSchemas(systemId: string, integrationId: string): Observable<PiiSystemSchemaResponse> {
    if (integrationId === 'all') integrationId = null;
		return this.apiClientPiiClassifierService.getPiiSystemSchema(systemId, integrationId).pipe(
			tap(res => this.schemaColumns.next(res.schemaNames)),
			tap(res => this.systemSchemaStore.set(this.prepateSchemaData(res.tableData, systemId))),
			catchError(error => this.handleError('getPiiSystemSchemas()', error))
		);
	}

  // update all relevant data for view before inserting to store (easier for filtering and viewing)
  prepateSchemaData(schemaItemsResponse: PiiSchemaItemResponse[], systemId: string): PiiSchemaItem[] {
    const system = this.systemsQuery.getEntity(systemId);
    return schemaItemsResponse?.map(s => {
      const dataType = this.dataTypesQuery.getEntity(this.convertPiiTypeToDataTypePipe.transform(s.piiDataType));

      return {
        ...s,
        id: `${s.piiDataType}-${s.rowId}`,
        policyViolation: this.policiesService.getDataTypeInSystemViolated(dataType, system),
        frameworks: this.frameworksService.getFrameworksChips([dataType]),
        dataType,
      } as PiiSchemaItem;
    });
  }

  getPiiSystemSchemaDeepDive(systemId: string, rowId: string, instanceId: string): Observable<PiiSchemaDeepDiveResponse> {
    if (!instanceId || instanceId === 'all') instanceId = null;
		return this.apiClientPiiClassifierService.getPiiSystemSchemaDeepDive(systemId, rowId, instanceId).pipe(
			catchError(error => this.handleError('getPiiSystemSchemaDeepDive()', error))
		);
	}

  handleError(functionName: string, error: Error): Observable<never> {
      this.logger.error(this.loggerName, `${functionName} Error: ${error.name} ,${error.message}`);
      return EMPTY;
  }

  getSchemaColumns(dynamicColumns: string[]): Map<string, TableColumn> {
    const columns = new Map<string, TableColumn>();
    const cols = this.contentPipe.transform('pii-classifier.schemaTableColumns') as TableColumn[];
    cols.filter(col => !col.featureFlag ||(col.featureFlag && this.featureFlagQuery.getFlag(col.featureFlag)))
      .forEach(col => {
      columns.set(col.key, {
        ...col, 
        isSortable: String(col.isSortable) === 'true',
        inView: !!col.inView, 
      });
    });

    //add dynamic columns
    dynamicColumns.forEach(col => {
      columns.set(col, {
        key: col,
        text: col,
        isSortable: true,
        inView: true, 
      });
    });
    return columns;
  }

  get schemaColumnsValue(): string[] {
    return this.schemaColumns.getValue();
  }
}
