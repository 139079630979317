import { Injectable } from '@angular/core';
import { PiiSystemStore } from './pii-system.store';
import { delay, Observable, of, tap } from 'rxjs';
import { PiiSystemResponse } from 'src/app/api/models/pii-classifier/pii-classifier.interface';
import { ApiClientPiiClassifierService } from 'src/app/api/api-client-pii-classifier.service';
import { PiiSystemQuery } from './pii-system.query';
import { PiiSystemInstance } from './pii-system.interface';
import { Router } from '@angular/router';
import { RoutesManager } from 'src/app/shared/models/routes.interfaces';
import { PiiDataTypesService } from '../pii-data-types-state/pii-data-types.service';
import { SystemSchemaService } from '../system-schema/system-schema.service';

@Injectable({
    providedIn: 'root'
})
export class PiiSystemService {
    private readonly loggerName = 'PiiSystemsService';

    constructor(
        private piiSystemStore: PiiSystemStore,
        private piiSystemQuery: PiiSystemQuery,
        private router: Router,
        private apiClientPiiClassifierService: ApiClientPiiClassifierService,
        private piiDataTypesService: PiiDataTypesService,
        private systemSchemaService: SystemSchemaService
    ) { }

    initState(): void {
        this.piiSystemStore.initStateConnections();
    }

    getInstanceData(systemId: string, instanceId: string): Observable<PiiSystemResponse> {
        this.piiSystemQuery.setActiveIntegrationId(null);
        this.piiDataTypesService.resetPiiDataTypesData();
        this.systemSchemaService.resetSchema();

        const instances = this.piiSystemQuery.getInstances()();
        
        if (!!instances.get(instanceId).systemId) {
            return of(instances.get(instanceId)).pipe(
                delay(500), tap(() => 
                this.piiSystemQuery.setActiveIntegrationId(instanceId)));
        }
        
        return this.apiClientPiiClassifierService.getPiiSystem(systemId, instanceId).pipe(
            delay(500),
            tap(instance => this.piiSystemQuery.addInstanceToStore(instanceId, instance)),
            tap(() => this.piiSystemQuery.setActiveIntegrationId(instanceId))
        );
    }

    getActiveInstance(): PiiSystemInstance {
        const activeId = this.piiSystemQuery.getActiveIntegrationId()();
        const instances = this.piiSystemQuery.getInstances()();
        return instances.get(activeId);
    }

    navigateToActiveInstance(): void {
        const activeIntegrationId = this.piiSystemQuery.getActiveIntegrationId()();
        const system = this.piiSystemQuery.getSystem()();
        const integrationId = activeIntegrationId === 'all' ? this.piiSystemQuery.getInstances()().keys().next().value : activeIntegrationId; //if instance is "all" get first integration
        this.router.navigate([RoutesManager.systems, system.pathFriendlyId], { state: { integrationId, scrollToIntegration: true }});
    }
    
    resetStore(): void {
        this.piiSystemStore.resetState();
    }

}