import { computed, Injectable, Signal } from '@angular/core';
import { map, Observable, tap } from 'rxjs';
import { ApiClientLabelsService } from 'src/app/api/api-client-labels.service';
import { AiAssessmentCustomLabel } from '../models/ai-assessments.interface';
import { LabelsStore } from '../state/ai-assessments-labels.store';

@Injectable({
     providedIn: 'root'
})
export class AiAssessmentsLabelsService {

    constructor(
        private apiClientLabelsService: ApiClientLabelsService,
        private store: LabelsStore,
    ) { }

    selectLoading(): Observable<boolean | undefined> {
        return this.store.select('loading');
    }

    getLoading(): Signal<boolean | undefined> {
        return this.store.signal('loading');
    }

    getEmpty(): Signal<boolean | undefined> {
        return this.store.signal('empty');
    }

    getLabels(asArray: boolean = false): Signal<Map<number, AiAssessmentCustomLabel> | AiAssessmentCustomLabel[]> {
        if (asArray) {
            return computed(() => Array.from(new Set([...this.store.signal('labels')().values()])));
        }
        return this.store.signal('labels');
    }

    selectLabels(asArray: boolean = false): Observable<Map<number, AiAssessmentCustomLabel> | AiAssessmentCustomLabel[]> {
        if (asArray) {
            return this.store.select('labels').pipe(
                map(labels => Array.from(new Set([...labels.values()])))
            );
        }
        return this.store.select('labels');
    }

    createLabel(label: AiAssessmentCustomLabel): Observable<AiAssessmentCustomLabel> {
        return this.apiClientLabelsService.createLabel(label).pipe(
           tap(savedLabel => this.store.addLabel(savedLabel))
        );
    }

    updateLabel(id: number, label: AiAssessmentCustomLabel): Observable<AiAssessmentCustomLabel> {
        return this.apiClientLabelsService.updateLabel(id, label).pipe(
            tap(() => this.store.updateLabel({ ...label, id }))
        );
    }

    deleteLabel(id: number): Observable<void> {
        return this.apiClientLabelsService.deleteLabel(id).pipe(
            tap(() => this.store.deleteLabel(id))
        );
    }

    getLabelByName(name: string): AiAssessmentCustomLabel {
        const labels = this.getLabels(true)() as AiAssessmentCustomLabel[];

        return labels.find(label => label.name === name);
    }

    getLabelNames(): Set<string> {
        const labels = this.getLabels(true)() as AiAssessmentCustomLabel[];
        return new Set(labels.map(label => label.name));
    }

    areLabelIdsEqual(
        a: AiAssessmentCustomLabel[],
        b: AiAssessmentCustomLabel[]
      ): boolean {
        if (a.length !== b.length) return false;
      
        const idsA = a.map(label => label.id ?? null).sort();
        const idsB = b.map(label => label.id ?? null).sort();
      
        return idsA.every((id, index) => id === idsB[index]);
      }
}